import Index from '../components/index'

import React from 'react'
import {graphql} from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import ImgTxt from '../components/imgTxt'
import TxtImg from '../components/txtImg'
import Social from '../components/social'
import News from '../components/news'
import LinkArray from '../components/linkArray'
import Video from '../components/video'

import ImgBot from '../components/imgBot'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    } 
    landingPage: sanityLandingPage{
      _rawPageBuilder(resolveReferences: {maxDepth: 10})
    }
  }
`



const IndexPage = props => {
  const {data, errors} = props
  const landingPage = data && data.landingPage

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const content = (landingPage._rawPageBuilder)
    .map((c) => {
      let el = null;
      switch (c._type) {
        case "imagemTexto":
          el = <ImgTxt key={c._key} {...c} />;
          break;
        case "textoSobreImg":
          el = <TxtImg key={c._key} {...c} />;
          break;
        case "social":
          el = <Social key={c._key} {...c} />;
          break;
        case "linkBlock":
          el = <News key={c._key} {...c} />;
          break;
        case "linkArray":
          el = <LinkArray key={c._key} {...c} />;
          break;
        default:
          el = null;
      }
      return el;
    }); 

  return (
    <Layout>
        <SEO
            title={site.title}
            description={site.description}
            keywords={site.keywords}
        />
        <Video />
        <div>{content}</div>
        <ImgBot />
    </Layout>
    // <>
    //   <SEO
    //     title={site.title}
    //     description={site.description}
    //     keywords={site.keywords}
    //   />
    //   <Index/>
    // </>
  )
}

export default IndexPage
