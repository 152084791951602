import React from 'react'
import logo from '../images/Ganhadeiras_ilustra1.png'

import styles from './index.module.css'

function Index () {
  return (
    <div className={styles.root}>
      <div className={styles.image}>
          <img src={logo} alt="As Ganhadeiras de Itapuã" />
      </div>
      <div className={styles.text}>
            <p className={styles.title}>Estamos preparando o novo site!</p>
            {/* <p className={styles.text1}>Insira o seu email e nós te avisaremos quando o site estiver no ar</p> */}
      </div>
      {/* <div className={styles.form}>
        <form action="/">
            <input type="email" id="email" name="email" placeholder="seu@email.com.br"/>
            <input type="submit" value="Me avise"/>
        </form>
      </div> */}
    </div>
  )
}

export default Index